import RelationshipWithSunTemplate from '@/modules/questionnaire/components/steps/questionnaire/oxidative-stress/relationship-with-sun/RelationshipWithSunTemplate';

export default {
  title: 'Steps/OxidativeStress/RelationshipWithSun/RelationshipWithSunTemplate'
};

const createStory = props => () => ({
  components: { RelationshipWithSunTemplate },
  storyProps: props,
  wrapStyles: {
    margin: '20px auto',
    width: '360px',
    maxWidth: '100%'
  },
  template: `<div :style='$options.wrapStyles'>
    <relationship-with-sun-template v-bind="$options.storyProps" />
  </div>`
});

export const Base = createStory({
  alwaysUsesSunscreen: 'yes',
  currentlyTanned: 'no'
});
